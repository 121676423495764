import React, { useState } from "react";
import "./Header.css";
import { useLocation, useNavigate } from 'react-router-dom';
import video1 from './assets/output.mp4';
import logo1 from './assets/m33.jpg';


const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);




  const handleMenuClick = () => {
    if (location.pathname === '/') {
      // Si estamos en la pantalla principal, desplazar hacia abajo
      const menuSection = document.getElementById('servicios');
      if (menuSection) {
        menuSection.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(!isMenuOpen);
      }
    } else {
      // Si estamos en otra pantalla, navegar directamente a /menu
      navigate('/servicios');
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleContactClick = () => {
    if (location.pathname === '/') {
      // Si estamos en la pantalla principal, desplazar hacia abajo
      const contactSection = document.getElementById('contacto');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(!isMenuOpen);
      }
    } else {
      // Si estamos en otra pantalla, navegar directamente a /menu
      navigate('/contacto');
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleNosotrosClick = () => {
    if (location.pathname === '/') {
      // Si estamos en la pantalla principal, desplazar hacia abajo
      const menuSection = document.getElementById('nosotros');
      if (menuSection) {
        menuSection.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(!isMenuOpen);
      }
    } else {
      // Si estamos en otra pantalla, navegar directamente a /menu
      navigate('/nosotros');
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const handleTrabajosClick = () => {
    if (location.pathname === '/') {
      // Si estamos en la pantalla principal, desplazar hacia abajo
      const menuSection = document.getElementById('trabajos');
      if (menuSection) {
        menuSection.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(!isMenuOpen);
      }
    } else {
      // Si estamos en otra pantalla, navegar directamente a /menu
      navigate('/trabajos');
      setIsMenuOpen(!isMenuOpen);
    }
  };

  return (

<header className="header">
  <div className="logo">
      <video autoPlay loop muted playsInline className="logo-video">
          <source src={video1} type="video/mp4" />
          Tu navegador no soporta videos en formato MP4.
      </video>
  </div>
  <div className="right-section">
    <img src={logo1} alt="Small Icon" className="small-icon" />
      <nav className="navbar">
              <button onClick={handleNosotrosClick}>Manifiesto</button>
              <button onClick={handleMenuClick}>Servicios</button>
              <button onClick={handleTrabajosClick}>Trabajos</button>
              <button onClick={handleContactClick}>Contacto</button>
      </nav>
  </div>
</header>
  );
};

export default Header;
