import React, { useState, useEffect } from "react";
import "./MainContent.css";
import car1 from "../assets/images/1.jpg";
import car2 from '../assets/images/2.jpg';
import car3 from '../assets/images/3.jpg';
import car4 from '../assets/images/4.jpg';
import car5 from "../assets/images/5.jpg";
import car6 from '../assets/images/6.jpg';
import car7 from '../assets/images/7.jpg';
import car8 from '../assets/images/8.jpg';
import car9 from '../assets/images/9.jpg';
import car10 from '../assets/images/10.jpg';
import car11 from "../assets/images/11.jpg";
import car12 from '../assets/images/12.jpg';
import car13 from '../assets/images/13.jpg';
import car14 from '../assets/images/14.jpg';
import car15 from "../assets/images/15.jpg";
import car16 from '../assets/images/16.jpg';
import car17 from '../assets/images/17.jpg';
import car18 from '../assets/images/18.jpg';
import car19 from '../assets/images/19.jpg';
import car20 from '../assets/images/20.jpg';
import car21 from "../assets/images/21.jpg";
import car22 from '../assets/images/22.jpg';
import car23 from '../assets/images/23.jpg';
import car24 from '../assets/images/24.jpg';
import car25 from "../assets/images/25.jpg";
import car26 from '../assets/images/26.jpg';
import car27 from '../assets/images/27.jpg';
import car28 from '../assets/images/28.jpg';
import video5 from './assets/ali.mp4';
import video4 from './assets/Pipe.mp4';
import video3 from './assets/sputnik.mp4';
import video2 from './assets/video111.mp4';
import video1 from './assets/video2.mp4';
import carvid1 from './assets/cvideo3.mp4';
import carvid2 from './assets/cvideo4.mp4';
import carvid3 from './assets/cvideo5.webm';

import { FaTiktok, FaInstagram, FaFacebook, FaYoutube, FaLinkedin, FaTwitch, FaDiscord } from 'react-icons/fa';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";

const MainContent = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (selectedImage) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [selectedImage]);

  useEffect(() => {
    const handleUserInteraction = () => {
      const videos = document.querySelectorAll("video:not(.no-autoplay)");
      videos.forEach((video) => {
        if (video.paused) {
          video.muted = true;
          video.play().catch((err) => {
            console.log("Autoplay bloqueado:", err);
          });
        }
      });

      // Remueve listeners luego del primer intento
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };

    window.addEventListener("click", handleUserInteraction);
    window.addEventListener("touchstart", handleUserInteraction);

    return () => {
      window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };
  }, []);

  return (
    <div className="all-sections-container">
      {/* Sección 1 */}
      <section className="section1">
        <div className="section1-content">
          <div className="three-columns">
            <h1 className="section1-title">
              <span className="hashtaggg">“</span>Every work <br />
              <span className="indent-text">should have</span> <br />
              <span className="indent-text">at least</span> <br />
              <span className="indent-text">ten meanings</span>
              <span className="hashtaggg2">“</span>
            </h1>
          </div>
          <p className="section1-subtitle">Walter de María.</p>
        </div>
        <div className="section1-image-area">
        <video autoPlay loop muted playsInline preload="auto" className="section1-image">
                  <source src={video4} type="video/mp4" />
                  Tu navegador no soporta videos en formato MP4.
              </video>
        </div>
      </section>

      {/* Sección 2 */}
      <section className="section2" id="nosotros">

        <div className="section2-image-area">
        <video autoPlay loop muted playsInline preload="auto" className="section2-image">
                  <source src={video3} type="video/mp4" />
                  Tu navegador no soporta videos en formato MP4.
              </video>
        </div>
        <div className="section2-text-area">
          <h1 className="section2-title">
            <span className="hashtag">#</span>Manifiesto
          </h1>
          <p className="section2-subtitle">
            <span className="indent-text">Las buenas</span> <br />
            <span className="indent-text">ideas venden.</span> <br />
            <span className="indent-text">Punto.</span>
          </p>
        </div>
      </section>

      {/* Sección 3 */}
      <section className="section3" id="servicios">
        <video autoPlay loop muted playsInline preload="auto" className="background-video">
          <source src={video2} type="video/mp4" />
          Tu navegador no soporta videos en formato MP4.
        </video>
      </section>

      <section className="section4">
        <div className="row first-row">
          <div className="column1">
            <h1>
              <span className="hashtag">#</span>Servicios
            </h1>
          </div>
          <div className="column2">
            <h2>Desde la estrategia hasta el arte ﬁnal.</h2>
          </div>
        </div>
        <div className="row second-row">
          <h3>
            Nos sentamos a pensar y repensar cómo es la marca hoy y como podría ser mañana. Pensamos conceptos que puedan expresarse en todos los medios.
          </h3>
          <div className="social-icons">
            <a ><span><FaTiktok /></span></a>
            <a ><span><FaInstagram /></span></a>
            <a ><span><FaFacebook /></span></a>
            <a ><span><FaYoutube /></span></a>
            <a ><span><FaLinkedin /></span></a>
            <a ><span><FaTwitch /></span></a>
            <a ><span><FaDiscord /></span></a>
          </div>
        </div>
      </section>

      <section className="section5" id="trabajos">
        <div className="row title-row">
          <h1>
            <span className="hashtag">#</span>Trabajos
          </h1>
        </div>

        {/* Carrusel de imágenes */}
        <div className="row carousel-row">
          <Swiper
            modules={[Autoplay]}
            spaceBetween={20}
            slidesPerView={1}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            loop={true}
            className="carousel"
          >
            {[car1, car2, car3, car4, car5, car6, car7, car8, car9, car10,
              car11, car12, car13, car14, car15, car16, car17, car18,
              car19, car20, car21, car22, car23, car24, car25, car26,
              car27, car28].map((img, index) => (
              <SwiperSlide key={index} className="carousel-slide">
                <img
                  src={img}
                  alt={`Imagen ${index + 1}`}
                  className="carousel-img"
                  onClick={() => setSelectedImage(img)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* Modal para ver imagen completa */}
        {selectedImage && (
            <div className="modal-overlay">
                <button className="close-button" onClick={() => setSelectedImage(null)}>
                &times;
                </button>
                <div className="modal-content">
                <img src={selectedImage} alt="Vista completa" />
                </div>
            </div>
        )}

            <div className="row video-grid">
            <div className="video-item">
                <video controls playsInline className="mosaic-video no-autoplay" poster="/thumbnails/thumb1.jpg">
                <source src={carvid1} type="video/mp4" />
                Tu navegador no soporta este video.
                </video>
            </div>
            <div className="video-item">
                <video controls playsInline className="mosaic-video no-autoplay" poster="/thumbnails/thumb2.jpg">
                <source src={carvid2} type="video/mp4" />
                Tu navegador no soporta este video.
                </video>
            </div>
            <div className="video-item">
                <video controls playsInline className="mosaic-video no-autoplay" poster="/thumbnails/thumb3.jpg">
                <source src={carvid3} type="video/mp4" />
                Tu navegador no soporta este video.
                </video>
            </div>
            </div>
      </section>

      <section className="section6" id="contacto">
        <div className="row first-row1">
          <div className="column11">
            <h1>
              <span className="hashtag">#</span>Contacto
            </h1>
          </div>
          <div className="column22"></div>
        </div>

        <div className="row second-row1">
          <div className="left-block">
              <div className="left-block-column">
                <p>hola@ninosanto.cl</p>
              </div>
          </div>

          <div className="section6-image-area">
              <video autoPlay loop muted playsInline preload="auto" className="section6-image">
                  <source src={video5} type="video/mp4" />
                  Tu navegador no soporta videos en formato MP4.
              </video>
          </div>

          <div className="new-block">
              <video autoPlay loop muted playsInline preload="auto" className="contact-video">
                <source src={video1} type="video/mp4" />
                Tu navegador no soporta la reproducción de videos.
              </video>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MainContent;
